import React from "react"

export default function Popup({ visible, handleClick }) {
  return (
    <div>
      {visible ? (
        <div className="fixed top-0 z-50 w-full h-full">
          {/* Popup */}
          <div className="w-[30%] z-50 absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 max-xl:w-[60%] max-md:w-[90%]">
            <div
              className="rounded-2xl border border-blue-100 bg-white p-4 shadow-lg sm:p-6 lg:p-8"
              role="alert"
            >
              <div className="flex items-center gap-4">
                <span className="shrink-0 rounded-full bg-blue-400 p-2 text-white">
                  <svg
                    className="h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z"
                      fillRule="evenodd"
                    />
                  </svg>
                </span>

                <p className="font-medium sm:text-lg">New Location!</p>
              </div>

              <p className="mt-2 text-gray-500 whitespace-pre-line">
                We will be located at{" "}
                <strong>
                  Shoppers World Brampton (499 Main Street South Brampton, L6Y
                  1N7) starting on Tuesday, July 9, 2024. 
                </strong>
                <br />
                <br />
                To find our new location,{" "}
                <strong>please visit the North East end of the mall near Rogers and Anderson College.</strong>
                <br />
                <br />
                <ul className="list-disc">
                  Our new hours will be:
                  <div className="ml-6">
                    <li>Monday: 10 AM - 9 PM</li>
                    <li>Tuesday: 10 AM - 9 PM </li>
                    <li>Wednesday: 10 AM - 9 PM </li>
                    <li>Thursday: 10 AM - 9 PM </li>
                    <li>Friday: 10 AM - 9 PM </li>
                    <li>Saturday: 9:30 AM - 6 PM </li>
                    <li>Sunday: 11 AM - 5 PM </li>
                  </div>
                </ul>
              </p>

              <div className="mt-6 sm:flex sm:gap-4">
                <a
                  className="inline-block w-full rounded-lg bg-blue-500 px-5 py-3 text-center text-sm font-semibold text-white sm:w-auto"
                  href="https://www.google.com/maps/place/Shoppers+World/@43.6662246,-79.7386601,17z/data=!3m1!5s0x882b3fd6429f005f:0xc36793e26ebd1cac!4m15!1m8!3m7!1s0x882b3fd6a882ba99:0x15e4e5241896d041!2s499+Main+St+S,+Brampton,+ON+L6Y+1N7!3b1!8m2!3d43.6647311!4d-79.736657!16s%2Fg%2F11csm1fwlw!3m5!1s0x882b3fd65a49da6d:0xe7bc05597309d52f!8m2!3d43.6647764!4d-79.7367901!16zL20vMDd6eF81?entry=ttu"
                  target="_blank"
                >
                  Take a Look
                </a>

                <a
                  className="mt-2 inline-block w-full rounded-lg bg-gray-100 px-5 py-3 text-center text-sm font-semibold text-gray-700 sm:mt-0 sm:w-auto"
                  onClick={handleClick}
                >
                  Mark as Read
                </a>
              </div>
            </div>
          </div>
          {/* Opacity */}
          <div
            className="w-full h-full bg-black opacity-70"
            onClick={() => handleClick()}
          ></div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

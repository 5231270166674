import React from "react"
import Shoppers from "./assets/ShoppersWorld.jpg"

export default function ShoppersWorld() {
  return (
    <section id="shoppers">
      <div className="mx-auto max-w-screen-xl px-4 py-4 sm:px-6 sm:py-12 lg:px-8 lg:py-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-first lg:h-full">
            <img
              alt=""
              src={Shoppers}
              className="absolute inset-0 h-full w-full object-cover"
            />
          </div>

          <div className="lg:py-24">
            <h2 className="text-3xl font-bold sm:text-4xl">New Location!</h2>
            <div className="mt-4 mx-auto text-gray-600">
              <div>
                We are now located at Shoppers World Brampton (499 Main Street South Brampton, L6Y 1N7)
                <br/><br/>
                To find our new location please visit the North East end of the mall near Rogers and Anderson College.
              </div>
              <br/>
              <span>Our new hours will be:</span>
              <ul className="ml-8 list-disc">
                  <li>
                      Monday: 10 AM - 9 PM
                  </li>
                  <li>
                      Tuesday: 10 AM - 9 PM
                  </li>
                  <li>
                      Wednesday: 10 AM - 9 PM
                  </li>
                  <li>
                      Thursday: 10 AM - 9 PM
                  </li>
                  <li>
                      Friday: 10 AM - 9 PM
                  </li>
                  <li>
                      Saturday: 9:30 AM - 6 PM
                  </li>
                  <li>
                      Sunday: 11 AM - 5 PM
                  </li>
              </ul>              
            </div>
            <a
                href="https://www.google.com/maps/place/Shoppers+World/@43.6662246,-79.7386601,17z/data=!3m1!5s0x882b3fd6429f005f:0xc36793e26ebd1cac!4m15!1m8!3m7!1s0x882b3fd6a882ba99:0x15e4e5241896d041!2s499+Main+St+S,+Brampton,+ON+L6Y+1N7!3b1!8m2!3d43.6647311!4d-79.736657!16s%2Fg%2F11csm1fwlw!3m5!1s0x882b3fd65a49da6d:0xe7bc05597309d52f!8m2!3d43.6647764!4d-79.7367901!16zL20vMDd6eF81?entry=ttu"
                target="_blank"
                className="mt-8 inline-block rounded bg-red-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-red-700"
                >
                Visit Today
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}